import { useNavigate } from "react-router-dom";
import HeroSection from "../../components/HeroSection";
import { useEffect, useState } from "react";
import { getPlans } from "../../services/Plans";
import StartYourFreeTrial from "../../components/StartYourFreeTrial";

const Pricing = () => {
    const navigate = useNavigate();
    const [standardPlans, setStandardPlans] = useState([]);
    const [premiumPlans, setPremiumPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPlans()
            .then(data => {
                const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
                const premium = data.filter((plan: any) => plan.planName.includes("Premium"));

                standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
                premium.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));

                setStandardPlans(standard);
                setPremiumPlans(premium);
            })
            .catch(error => console.error('Error fetching plans data:', error))
            .finally(() => setLoading(false));
    }, []);

    const handleCheckout = (plan: any) => {
        navigate('/checkout', {
            state: {
                planId: plan.id,
                planName: plan.planName,
                price: plan.price,
                billingCycle: plan.billingCycle,
                source: 'pricing'
            }
        });
    };

    const renderPlanDetails = (plan: any) => (
        <div key={plan.id} className="md:w-1/2 lg:w-1/2">
            <p className="font-[cursive] text-xl md:text-2xl text-[#597820]">{plan.description}</p>
            <p className="text-xl md:text-3xl font-bold my-2 text-[#2B3998]">
                ${plan.price}/{plan.billingCycle.toLowerCase()}
            </p>
            <button
                className="mb-3 bg-blue-700 text-white rounded-md py-2 px-4 text-sm md:text-base uppercase hover:bg-blue-900 hover:cursor-pointer"
                onClick={() => handleCheckout(plan)}
            >
                Start Membership
            </button>
        </div>
    );

    const renderPlans = (plans: any) => (
        <div className="md:flex flex-nowrap">
            {plans.map((plan: any) => renderPlanDetails(plan))}
        </div>
    );

    const Loader = () => (
        <div className="flex justify-center items-center">
            <svg
                className="animate-spin h-8 w-8 mr-3 text-[#181842]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            <span className="text-xl font-semibold text-[#181842]">Loading...</span>
        </div>
    );

    return (
        <div>
            <HeroSection
                mainHeading="My Church Directory  Pricing"
                backgroundImage="../images/smallChurch.jpg"
            />

            <section className="w-[90%] mx-auto px-4 py-8">
                <div className="md:flex flex-wrap">
                    <div className="md:w-full lg:w-1/2 text-center mb-4">
                        <div className="p-4">
                            <h2 className="text-xl md:text-3xl text-[#2B3998] mb-4">Standard Membership</h2>
                            {loading ? (
                                <Loader />
                            ) : (
                                renderPlans(standardPlans)
                            )}
                        </div>
                    </div>

                    <div className="md:w-full lg:w-1/2 text-center">
                        <div className="p-4">
                            <h2 className="text-xl md:text-3xl text-[#2B3998] mb-4">Premium Membership</h2>
                            {loading ? (
                                <Loader />
                            ) : (
                                renderPlans(premiumPlans)
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[#f8f8f8] px-4 py-8">

                <div className="max-w-screen-lg mx-auto">

                    <h2 className="text-4xl mt-10 mb-2 text-[#2B3998] font-semibold">Try it FREE for 30 days.</h2>
                    <p className="text-lg mt-3 text-justify">If you like <em>My Church Directory </em>, do nothing and your membership will automatically continue after your free trial period is over. All your directory information will be saved so you won't have to start over.</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-16  my-14">
                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">Pricing Transparency</h2>
                            <p className="text-sm md:text-base text-justify">Our pricing model is simple. There are no membership-size price tiers to worry about. My Church Directory  is ready to grow with your congregation. Add or remove members as you need, without any worry of changing your pricing structure. Plus you can update your directory as often as you'd like with no limits.</p>
                        </div>

                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">What will this cost my members?</h2>
                            <p className="text-sm md:text-base text-justify">With My Church Directory , creating and maintaining a directory will never cost your individual members anything. There are no photo packages for your members to feel obligated to purchase. The mobile apps and online directory website are free to your members with a paid church subscription. The only cost is for the monthly or yearly membership to create and maintain your directory.</p>
                        </div>

                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">No contracts — cancel anytime.</h2>
                            <p className="text-sm md:text-base text-justify">Start your membership today and choose either a monthly or yearly billing plan. Regardless of the billing plan you choose, there are never any commitments to continue. You can cancel your membership at any time.</p>
                        </div>

                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">What is the cost for text messaging?</h2>
                            <p className="text-sm md:text-base text-justify">Text messaging is a pay-as-you-go service. Purchase credits only when you are ready to send a text. There is no additional monthly fee and no hidden costs. Credit plans start at $5 for 200 texts. Learn more about plans and pricing.

                                PREMIUM FEATURE</p>
                        </div>

                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">What does yearly billing mean?</h2>
                            <p className="text-sm md:text-base text-justify">With yearly billing, your credit card will be charged for the entire year's membership following your free trial period. Yearly billing has an advantage over the monthly billing price, because it provides a discount to customers. And who doesn't like to save money? You're never obligated to continue your membership, and you can cancel any time</p>
                        </div>

                        <div className="md:col-span-1">
                            <h2 className="text-3xl mb-5 text-[#2B3998]">Customize Your Directory</h2>
                            <p className="text-sm md:text-base text-justify">Customize your directory effortlessly with My Church Directory  Premium. Tailor your logo, colors, and tab labels to match your branding and preferences. Enjoy all the perks of the standard subscription while unlocking personalized touches that resonate with your church or organization, for just $14.99 per month. Learn more about the Premium option.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="px-4 py-8">

                <div className="max-w-screen-lg mx-auto"><h1 className=" text-4xl text-[#2B3998] py-12 font-semibold">Transparent pricing with no billing surprises.</h1></div>


                <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row items-center gap-8">
                    <img
                        src="../images/billing-timeline.png"
                        alt=""
                        className="w-70 md:w-1/2 h-auto md:h-[300px] mb-4 md:mb-0 px"
                    />
                    <p className="max:w-30 md:w-1/2 mt-12 text-justify">
                        We have tried to keep our billing as transparent and simple as we can. We
                        will always notify you before a billing event happens. If you sign up
                        today and decide that My Church Directory  isn't for you, then just
                        make sure to cancel your account when you receive your reminder email.
                    </p>
                </div>
            </section>

            <StartYourFreeTrial />
        </div>
    );
}

export default Pricing;