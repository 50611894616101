import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import Cookies from 'js-cookie';
import { getGroups } from '../../services/Group';
import { getEvent, put_Event } from '../../services/Event';
import baseURL from '../../config/api';

const EditEvent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const [userData, setUserData] = useState<any>({
    id: 0,
    eventName: "",
    groupId: 0,
    subGroupId: 0,
    startDate: "",
    endDate: "",
    sessions: "",
    eventCoordinatorNameEmailAddress: "",
    eventCoordinatorEmail: "",
    eventCoordinatorPhone: "",
    guest: "",
    description: "",
    isActive: true,
    location: ""
  });
  const [groupData, setGroupData] = useState<any[]>([]);
  const [subGroupData, setSubGroupData] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const role = Cookies.get("role-my-cdir") || "";
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);

  useEffect(() => {
    if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "admin") {
      setIsSuperAdmin(false);
    } else if (role?.toString()?.toLowerCase().replaceAll(" ", "") === "superadmin") {
      setIsSuperAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (role?.toString()?.toLowerCase() === "superadmin") {
      getGroups()
        .then(res => {
          setGroupData(res.map((item: any) => {
            return {
              id: item.id,
              name: item.name
            }
          }));
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedGroupId > 0) {
      fetch(`${baseURL}subGroup/GetSubGroupsByGroupId/${selectedGroupId}`)
        .then((res) => res.json())
        .then((data) => {
          setSubGroupData(data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              groupId: item.groupId
            }
          }
          )
          )
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId])



  useEffect(() => {
    if (id) {
      fetch(`${baseURL}subGroup/GetSubGroupsByGroupId/${state}`)
        .then((res) => res.json())
        .then((data) => {
          getEvent(id)
            .then(res => {
              setUserData(res);
              setSubGroupData(data.map((item: any) => {
                return {
                  value: item.id,
                  label: item.name,
                  groupId: item.groupId
                }
              }))
            })
            .catch(error => console.log(error));
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const closeModal = () => navigate(-1);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isProcessing) {
      setIsProcessing(true);

      put_Event(userData)
        .then(() => {
          setIsProcessing(false);
          navigate(-1);
        })
        .catch(error => {
          setIsProcessing(false);
          console.error(error);
        });
    }
  }

  return (
    <div className="w-full">
      <div className="my-2">
        <div className="py-8 px-6 relative bg-white">
          <h2 className="text-xl text-blue-500 font-bold">Edit Event</h2>
          <form onSubmit={handleSubmit} className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
            <div>
              <label className="font-semibold text-sm">Event Name</label>
              <input type='text' placeholder='Enter Event Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.eventName}
                onChange={(e) => setUserData({ ...userData, eventName: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Event Coordinator Name</label>
              <input type='text' placeholder='Enter Event Coordinator Name'
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.eventCoordinatorNameEmailAddress}
                onChange={(e) => setUserData({ ...userData, eventCoordinatorNameEmailAddress: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Event Coordinator Email</label>
              <input type='email' placeholder='Enter Event Coordinator Email'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.eventCoordinatorEmail}
                onChange={(e) => setUserData({ ...userData, eventCoordinatorEmail: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Event Coordinator Phone Number</label>
              <input type='text' placeholder='Enter Event Coordinator Phone No.'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                value={userData.eventCoordinatorPhone}
                onChange={(e) => setUserData({ ...userData, eventCoordinatorPhone: onChangePhoneNumber(e.target.value) })}
              />
            </div>
            {isSuperAdmin &&
              <div>
                <label className="font-semibold text-sm">Church/Organization</label>
                <select
                  className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                  value={userData.groupId}
                  onChange={(e) => {
                    setUserData({ ...userData, groupId: parseInt(e.target.value) })
                    setSelectedGroupId(parseInt(e.target.value))
                  }
                  }

                  required
                >
                  {groupData.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            }
            <div>
              <label className="font-semibold text-sm">Sub Group</label>
              <select
                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                value={userData.subGroupId}
                onChange={(e) => setUserData({ ...userData, subGroupId: parseInt(e.target.value) })}
                required
              >
                <option value={0}>All</option>
                {subGroupData.map((item) => (
                  <option key={item.value} value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            {!isSuperAdmin && <div />}
            <div>
              <label className="font-semibold text-sm">Start Date</label>
              <input ref={startDateRef} type='datetime-local'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.startDate}
                onChange={(e) => setUserData({ ...userData, startDate: e.target.value })}
                onClick={() => startDateRef?.current?.showPicker()}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">End Date</label>
              <input ref={endDateRef} type='datetime-local'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.endDate}
                onChange={(e) => setUserData({ ...userData, endDate: e.target.value })}
                onClick={() => endDateRef?.current?.showPicker()}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Guest</label>
              <input type='text'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.guest}
                onChange={(e) => setUserData({ ...userData, guest: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Sessions</label>
              <input type='text'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.sessions}
                onChange={(e) => setUserData({ ...userData, sessions: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Location</label>
              <input type='text'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.location}
                onChange={(e) => setUserData({ ...userData, location: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="font-semibold text-sm">Active</label>
              <div className="flex items-center font-semibold text-sm gap-4  h-1/2">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="Yes"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={userData.isActive ? true : false}
                    onChange={(e) => setUserData({ ...userData, isActive: e.target.value === "Yes" ? true : false })}
                  />
                  <label>Yes</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    value="No"
                    name="active"
                    style={{ fontSize: "12px" }}
                    checked={userData.isActive ? false : true}
                    onChange={(e) => setUserData({ ...userData, isActive: e.target.value === "Yes" ? true : false })}
                  />
                  <label>No</label>
                </div>
              </div>
            </div>
            <div>
              <label className="font-semibold text-sm">Description</label>
              <textarea placeholder='Description'
                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                value={userData.description}
                onChange={(e) => setUserData({ ...userData, description: e.target.value })}
              />
            </div>
            <div />

            <div className="flex max-sm:flex-col gap-4 !mt-4">
              <button
                type="button"
                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2 min-w-[150px] rounded text-white text-sm font-semibold border-none outline-none bg-blue-500 hover:bg-blue-700"
              >
                {isProcessing ?
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" fill="#fff" className="mr-2 inline animate-spin"
                      viewBox="0 0 26.349 26.35">
                      <circle cx="13.792" cy="3.082" r="3.082" data-original="#000000" />
                      <circle cx="13.792" cy="24.501" r="1.849" data-original="#000000" />
                      <circle cx="6.219" cy="6.218" r="2.774" data-original="#000000" />
                      <circle cx="21.365" cy="21.363" r="1.541" data-original="#000000" />
                      <circle cx="3.082" cy="13.792" r="2.465" data-original="#000000" />
                      <circle cx="24.501" cy="13.791" r="1.232" data-original="#000000" />
                      <path
                        d="M4.694 19.84a2.155 2.155 0 0 0 0 3.05 2.155 2.155 0 0 0 3.05 0 2.155 2.155 0 0 0 0-3.05 2.146 2.146 0 0 0-3.05 0z"
                        data-original="#000000" />
                      <circle cx="21.364" cy="6.218" r=".924" data-original="#000000" />
                    </svg>
                    Processing...
                  </>
                  :
                  "Save"
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditEvent