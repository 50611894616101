import { useEffect, useState } from 'react'
import { MdEvent } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { RxCross1 } from 'react-icons/rx';
import { BsSearch } from 'react-icons/bs';
import { RiArrowDownSFill } from 'react-icons/ri';
import baseURL from '../../config/api';
import Pagination from '../../components/Pagination';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import moment from 'moment';

const heading = ["Event Name", "Start Date", "End Date", "Event Coordinator Name", "Event Coordinator Email", "Event Coordinator Phone", "Guest", "Acitve", "Actions"];
const currentDate = moment().format("YYYY-MM-DD");
const MemberEvent = () => {
    const navigate = useNavigate();
    const groupIdAuth = Cookies.get("groupId-my-cdir") || "";
    const [showEntries, setShowEntries] = useState<number>(5);
    const [searchTableData, setSearchTableData] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [displayFilter, setDisplayFilter] = useState<boolean>(false);
    const [eventFilterValue, setEventFilterValue] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [paginationModel, setPaginationModel] = useState<{ page: number, size: number }>({
        page: 0,
        size: 5,
    });
    const [totalLength, setTotalLength] = useState<number>(0);
    const [selectedFilter, setSelectedFilter] = useState<string>("Event Name");
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const [selectedEventDropDown, setSelectedEventDropDown] = useState<number>(0);


    useEffect(() => {
        if (!isFirstTime) {
            setSelectedFilter("Event Name");
            setEventFilterValue(0);
            if (searchValue !== "") {
                setSearchValue("");
                setIsFirstTime(false);
            }
            else {
                setPaginationModel({
                    page: 0,
                    size: 5
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEventDropDown])

    useEffect(() => {
        if (searchValue === "" && !isFirstTime) {
            const newPaginationModel = {
                page: 0,
                size: paginationModel.size,
            };

            setPaginationModel(newPaginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue === "") {
            setIsFirstTime(false)
            apiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const apiCall = (newPaginationModel: { page: number, size: number }) => {
        if (parseInt(groupIdAuth) > 0) {

            if (selectedEventDropDown === 0) {
                setIsDataLoaded(true);
                fetch(`${baseURL}event/GetEventReportByGroupIdPaging/${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`)
                    .then((res) => res.json())
                    .then((data) => {
                        const result = data.eventReport.map((item: any) => {
                            return {
                                ...item,
                                startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                isActive: item.isActive ? "Yes" : "No"
                            };
                        })

                        setSearchTableData(result);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    }).catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });
            }
            else {
                setIsDataLoaded(true);
                fetch(`${baseURL}event/GetEventReportByGroupIdForUpcomingEventPaging/${currentDate},${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`)
                    .then((res) => res.json())
                    .then((data) => {
                        const result = data.eventReport.map((item: any) => {
                            return {
                                ...item,
                                startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                isActive: item.isActive ? "Yes" : "No"
                            };
                        })

                        setSearchTableData(result);
                        setTotalLength(data.count);
                        setIsDataLoaded(false);

                    }).catch((error) => {
                        console.log(error);
                        setIsDataLoaded(false);
                        setSearchTableData([]);
                        setTotalLength(0);
                    });

            }
        }
        else {
            setIsDataLoaded(false);
            setSearchTableData([]);
            setTotalLength(0);
        }
    }

    useEffect(() => {
        let timeOutId: NodeJS.Timeout;

        if (searchValue !== "") {
            timeOutId = setTimeout(() => {
                const newPaginationModel = {
                    page: 0,
                    size: paginationModel.size,
                };

                setPaginationModel(newPaginationModel);

            }, 1000);
        }

        return () => clearTimeout(timeOutId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (searchValue !== "" && !isFirstTime) {
            filteApiCall(paginationModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel])

    const filteApiCall = (newPaginationModel: { page: number, size: number }) => {
        let filterData: any[] = [];
        setIsDataLoaded(true);
        if (parseInt(groupIdAuth) > 0) {
            if (selectedEventDropDown === 0) {
                switch (eventFilterValue) {
                    case 0:
                        fetch(
                            `${baseURL}event/GetEventReportByGroupIdAndSearchWithEventName/${searchValue},${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                filterData = data.eventReport.map((item: any) => {
                                    return {
                                        ...item,
                                        startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        isActive: item.isActive ? "Yes" : "No"
                                    };
                                })

                                setSearchTableData(filterData);
                                setTotalLength(data.count);
                                setIsDataLoaded(false);

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsDataLoaded(false);
                                setSearchTableData([]);
                                setTotalLength(0);
                            });

                        break;
                    case 1:
                        fetch(
                            `${baseURL}event/GetEventReportByGroupIdAndSearchWithEventCoordinatorNameEmailAddress/${searchValue},${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                filterData = data.eventReport.map((item: any) => {
                                    return {
                                        ...item,
                                        startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        isActive: item.isActive ? "Yes" : "No"
                                    };
                                })
                                setSearchTableData(filterData);
                                setTotalLength(data.count);
                                setIsDataLoaded(false);

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsDataLoaded(false);
                                setSearchTableData([]);
                                setTotalLength(0);
                            });

                        break;

                }
            }
            else {
                switch (eventFilterValue) {
                    case 0:
                        fetch(
                            `${baseURL}event/GetEventReportByGroupIdAndSearchWithEventNameForUpcomingEvent/${currentDate},${searchValue},${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                filterData = data.eventReport.map((item: any) => {
                                    return {
                                        ...item,
                                        startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        isActive: item.isActive ? "Yes" : "No"
                                    };
                                })

                                setSearchTableData(filterData);
                                setTotalLength(data.count);
                                setIsDataLoaded(false);

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsDataLoaded(false);
                                setSearchTableData([]);
                                setTotalLength(0);
                            });

                        break;
                    case 1:
                        fetch(
                            `${baseURL}event/GetEventReportByGroupIdAndSearchWithEventCoordinatorNameEmailAddressForUpcomingEvent/${currentDate},${searchValue},${parseInt(groupIdAuth)},${newPaginationModel.page},${newPaginationModel.size}`
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                filterData = data.eventReport.map((item: any) => {
                                    return {
                                        ...item,
                                        startDate: item.startDate ? format(new Date(item.startDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        endDate: item.endDate ? format(new Date(item.endDate), "MM/dd/yyyy hh:mm a") : "No date",
                                        isActive: item.isActive ? "Yes" : "No"
                                    };
                                })
                                setSearchTableData(filterData);
                                setTotalLength(data.count);
                                setIsDataLoaded(false);

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsDataLoaded(false);
                                setSearchTableData([]);
                                setTotalLength(0);
                            });

                        break;

                }
            }
        }
        else {
            setIsDataLoaded(false);
            setSearchTableData([]);
            setTotalLength(0);
        }
    }

    const applyFilter = () => {
        setSelectedFilter(() =>
            eventFilterValue === 0 ? "Event Name" : "Coordinator Name"
        );
        setSearchValue("");
        setDisplayFilter(false);
    };

    const clearFilter = () => {
        setSearchValue("");
        setDisplayFilter(false);
    };




    const viewEvent = (id: string) => {
        navigate(`/member/view-event/${id}`);
    }




    return (
        <section className="event__container overflow-x-auto min-w-full">
            <div className="min-w-full flex flex-row justify-between item-center">
                <h1 className="px-4 py-1.5 flex items-center gap-2">
                    <MdEvent fontSize={20} />
                    <span className="text-lg">Event</span>
                </h1>
                <div className="flex gap-2 md:gap-4">
                    <select
                        name="group"
                        className="border-2 border-blue-500 px-2 outline-none"
                        value={selectedEventDropDown}
                        onChange={(e) => setSelectedEventDropDown(parseInt(e.target.value))}>
                        <option value='0'>All</option>
                        <option value='1'>Upcoming</option>
                    </select>
                </div>
            </div>

            <div className="flex space-x-3 justify-between items-center border border-black p-1 md:p-2 mt-4">
                <div className="relative flex flex-1 space-x-3 items-center">
                    <div className='relative'>
                        <button
                            className="bg-blue-500 text-white px-3 py-1 rounded-md flex items-center hover:bg-blue-700 focus:outline-none"
                            onClick={() => setDisplayFilter(!displayFilter)}
                        >
                            {selectedFilter}
                            <RiArrowDownSFill className="ml-1" />
                        </button>

                        <div
                            className={`${displayFilter ? "block" : "hidden"
                                } absolute w-40 ml-1 p-2 bg-white border border-black top-0 left-full`}
                        >
                            <div className="flex justify-between">
                                <label className="text-xs font-bold" htmlFor="fields">
                                    Fields
                                    <br />
                                </label>
                                <div
                                    onClick={() => setDisplayFilter(false)}
                                    className="w-4 h-4 cursor-pointer text-sm text-center items-center"
                                >
                                    <RxCross1 />
                                </div>
                            </div>
                            <select
                                className="w-full text-xs py-1 border mt-1"
                                name="fields"
                                value={eventFilterValue}
                                onChange={(e) =>
                                    setEventFilterValue(parseInt(e.target.value))
                                }
                            >
                                <option value="0">Event Name</option>
                                <option value="1">Coordinator Name</option>

                            </select>

                            <div className='flex mt-2 justify-between whitespace-nowrap'>
                                <button
                                    className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                    onClick={() => applyFilter()}
                                >
                                    Apply Filter
                                </button>

                                <button
                                    className="text-xs bg-blue-500 text-white py-1 px-2 rounded-md mt-3 hover:bg-blue-600 focus:outline-none"
                                    onClick={() => clearFilter()}
                                >
                                    Clear
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className='flex-1'>
                        <div className="flex flex-1 items-center gap-2">
                            <div className="h-4 w-4 text-gray-900">
                                <BsSearch />
                            </div>

                            <input
                                type="text"
                                placeholder="Search…"
                                className="py-1 text-lg pl-2 outline-none flex-1"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-2" />
            {!isDataLoaded ?
                (
                    <>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white">
                                <thead className="bg-gray-200 whitespace-nowrap">
                                    <tr>
                                        {heading.map((item, index) => (
                                            <th key={index} className="px-6 py-3 text-left text-sm font-semibold text-black">
                                                {item}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="whitespace-nowrap">
                                    {searchTableData.length > 0 ? (
                                        searchTableData.slice(0, showEntries).map((item, index) => {
                                            return (
                                                <tr key={index} className="even:bg-blue-50">
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.eventName}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.startDate}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.endDate}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.eventCoordinatorNameEmailAddress}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.eventCoordinatorEmail}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.eventCoordinatorPhone}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.guest}
                                                    </td>
                                                    <td className="px-6 py-4 text-sm">
                                                        {item.isActive}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <button className="mr-4" title="Edit" onClick={() => viewEvent(item.id)}>
                                                            <MdOutlineRemoveRedEye className='text-blue-500 text-xl' />
                                                        </button>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )
                                        :
                                        (
                                            <tr>
                                                <td colSpan={9} className="px-2 py-1 text-sm font-medium text-center h-52">No data available</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            totalLength > 0 && (
                                <Pagination
                                    pageOption={[5, 10, 15, 20, 25, 50, 75, 100]}
                                    totalLength={totalLength}
                                    paginationModel={paginationModel}
                                    setPaginationModel={setPaginationModel}
                                    showEntries={showEntries}
                                    setShowEntries={setShowEntries}
                                />
                            )
                        }

                    </>
                )
                :
                (<div id="loading-colors" className="min-w-full h-[480px] flex flex-col flex-1 justify-center items-center">
                    <div className="flex flex-col items-center">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500"
                            role="status"></div>
                        <span className="text-black">Loading...</span>
                    </div>
                </div>)
            }
        </section>
    )
}

export default MemberEvent