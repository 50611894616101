import { useEffect, useRef, useState } from 'react';
import YourFreeTrialOffer from '../../components/YourFreeTrialOffer';
import Input from '../../components/Input';
import baseURL from '../../config/api';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEmailChecking } from '../../services/EmailChecking';
import { getUsername } from '../../services/UsernameChecking';
import { FaCheckCircle } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { addMonths, addYears } from 'date-fns';
import HeroSection from '../../components/HeroSection';
import { getPlans } from '../../services/Plans';

interface Plan {
    id: number;
    planName: string;
    price: number;
  }

const Checkout = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [streetAddress, setStreetAddress] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [card, setCard] = useState<string>("");
    const [cardHolderName, setCardHolderName] = useState<string>("");
    const [expirationDate, setExpirationDate] = useState<string>("");
    const [ccvCode, setCcvCode] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isEmailAvailable, setIsEmailAvailable] = useState<boolean>(false);
    const [showUsernameErrorMessage, setShowUsernameErrorMessage] = useState<boolean>(false);
    const [showUsernameSuccessMessage, setShowUsernameSuccessMessage] = useState<boolean>(false);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState<boolean>(false);
    const [showEmailSuccessMessage, setShowEmailSuccessMessage] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);
    const [passwordTextType, setPasswordTextType] = useState<boolean>(false);
    const [couponCode, setCouponCode] = useState('');
    const location = useLocation();
    const [standardPlan, setStandardPlan] = useState<Plan | null>(null);

    useEffect(() => {
      if (!location.state) {
        getPlans()
          .then((data: any) => {
            const standard = data.filter((plan: any) => plan.planName.includes("Standard"));
            standard.sort((a: any, b: any) => a.billingCycle.localeCompare(b.billingCycle));
            setStandardPlan(standard[0]);
          })
          .catch(error => console.error('Error fetching plans data:', error));
      }
    }, [location.state]);
    
    const planId = location.state?.planId || standardPlan?.id;
    const planName = location.state?.planName || standardPlan?.planName;
    const price = location.state?.price || standardPlan?.price;
    const billingCycle = location.state?.billingCycle || 'month';
    const source = location.state?.source || 'free-trial';

    const [discountId, setDiscountId] = useState<number | null>(null);
    const [selectedMethod, setSelectedMethod] = useState('Credit Card');
    const [bankName, setBankName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [bankPhoneNumber, setBankPhoneNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [useAccountForFuture, setUseAccountForFuture] = useState(false);
    const [signedAuthorization, setSignedAuthorization] = useState(false);
    const [discountMessage, setDiscountMessage] = useState('');
    const [finalPrice, setFinalPrice] = useState(price);
    const [expirationDateError, setExpirationDateError] = useState("");
    const [isExpirationDateValid, setIsExpirationDateValid] = useState<boolean>(false);
    

    const handleMethodChange = (event: any) => {
        setSelectedMethod(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setPasswordTextType(!passwordTextType);
    };

    const handlePasswordChange = (value: string) => {
        setPassword(value);

        if (value.length > 0) {
            setIsPasswordFocused(true);
        } else {
            setIsPasswordFocused(false);
        }

        const isValidPassword =
            value.length >= 8 &&
            /\d/.test(value) &&
            /[a-z]/.test(value) &&
            /[A-Z]/.test(value) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(value);

        setIsPasswordValid(isValidPassword);

        if (!isValidPassword && isPasswordFocused) {
            console.error("Password must be at least 8 characters long and include at least one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (!@#$%^&*()).");
        }
    };

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (userName.trim() !== '') {
            debounceTimer = setTimeout(() => {
                checkUsernameAvailability(userName.trim());
            }, 1000);
        } else {
            setIsUsernameAvailable(false);
            setShowUsernameErrorMessage(false);
            setShowUsernameSuccessMessage(false);
        }

        return () => clearTimeout(debounceTimer);
    }, [userName]);

    const checkUsernameAvailability = (username: string) => {
        getUsername(username)
            .then((response: boolean) => {
                setIsUsernameAvailable(!response);
                setShowUsernameErrorMessage(response);
                if (!response) {
                    setShowUsernameSuccessMessage(true);
                    setTimeout(() => {
                        setShowUsernameSuccessMessage(false);
                    }, 3000);
                }
                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Username is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking username availability:', error);
                setIsUsernameAvailable(false);
                setShowUsernameErrorMessage(false);
                setShowUsernameSuccessMessage(false);
            });
    };

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout;

        if (email.trim() !== '') {
            debounceTimer = setTimeout(() => {
                const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/gm.test(email.trim());
                setIsEmailValid(isValid);
                if (isValid) {
                    checkEmailAvailability(email.trim());
                } else {
                    setIsEmailAvailable(false);
                    setShowEmailErrorMessage(false);
                    setShowEmailSuccessMessage(false);
                }
            }, 1000);
        } else {
            setIsEmailValid(true);
            setIsEmailAvailable(false);
            setShowEmailErrorMessage(false);
            setShowEmailSuccessMessage(false);
        }

        return () => clearTimeout(debounceTimer);
    }, [email]);

    const checkEmailAvailability = (email: string) => {
        getEmailChecking(email)
            .then((response: boolean) => {
                setIsEmailAvailable(response);
                setShowEmailErrorMessage(response);
                setShowEmailSuccessMessage(!response);

                if (!response) {
                    setTimeout(() => {
                        setShowEmailSuccessMessage(false);
                    }, 3000);
                }

                if (inputRef.current) {
                    inputRef.current.setCustomValidity(response ? "Email is already exist" : "");
                }
            })
            .catch((error: Error) => {
                console.error('Error checking email availability:', error);
                setIsEmailAvailable(false);
                setShowEmailErrorMessage(false);
                setShowEmailSuccessMessage(false);
            });
    };

    const onChangePhoneNumber = (value: string, type: "phone" | "bankPhone") => {
        const numericValue = value.replace(/[^0-9]+/g, "");
        const trimmedValue = numericValue.slice(0, 10);
        let formattedValue = "";

        if (trimmedValue.length <= 3) {
            formattedValue = trimmedValue;
        } else if (trimmedValue.length <= 6) {
            formattedValue = `(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3)}`;
        } else if (trimmedValue.length <= 10) {
            formattedValue = `(${trimmedValue.slice(0, 3)}) ${trimmedValue.slice(3, 6)}-${trimmedValue.slice(6)}`;
        }

        if (type === "phone") {
            setPhone(formattedValue);
        } else if (type === "bankPhone") {
            setBankPhoneNumber(formattedValue);
        }
    };

    const handlePostalCodeInputChange = (value: string) => {
        const numericValue = value.replace(/[^0-9]+/g, "");
        setPostalCode(numericValue);
    };

    const handleCardInputChange = (value: string) => {
        const numericValue = value.replace(/[^\d]+/g, "");
        setCard(numericValue);
    };

    const handleCardHolderNameInputChange = (value: string) => {
        const alphabeticValue = value.replace(/[^A-Za-z\s]+/g, "");
        setCardHolderName(alphabeticValue);
    };

    const handleExpirationDateInputChange = (value: string) => {
        const numericValue = value.replace(/[^0-9]+/g, "");
        let formattedValue = numericValue.slice(0, 4);
        let formattedDate = formattedValue;

        if (formattedValue.length >= 3) {
            formattedDate = formattedValue.replace(/^(\d{2})(\d{0,2})/, "$1/$2");
        }

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = parseInt(currentDate.getFullYear().toString().slice(-2), 10);

        let errorMessage = "";
        let isValid = true;

        if (formattedValue.length === 4) {
            const enteredMonth = parseInt(formattedValue.slice(0, 2), 10);
            const enteredYear = parseInt(formattedValue.slice(2, 4), 10);

            if (enteredMonth < 1 || enteredMonth > 12) {
                errorMessage = "Invalid month. Please enter a month between 01 and 12.";
                isValid = false;
            } else if (enteredYear < currentYear || (enteredYear === currentYear && enteredMonth < currentMonth)) {
                errorMessage = "Expiration date must be in the future.";
                isValid = false;
            }
        } else if (formattedValue.length > 0 && formattedValue.length < 4) {
            errorMessage = "Please complete the expiration date in MM/YY format.";
            isValid = false;
        } else if (formattedValue.length === 0) {
            errorMessage = "Expiration date is required.";
            isValid = false;
        }

        setExpirationDate(formattedDate);
        setExpirationDateError(errorMessage);
        setIsExpirationDateValid(isValid);
    };

    useEffect(() => {
        if (selectedMethod !== 'Credit Card') {
            setCard("");
            setCardHolderName("");
            setExpirationDate("");
            setCcvCode("");
            setExpirationDateError("");
            setIsExpirationDateValid(true);
        }
    }, [selectedMethod]);

    const handleCcvCodeInputChange = (value: string) => {
        const numericValue = value.replace(/[^0-9]+/g, "");
        const formattedValue = numericValue.slice(0, 4);
        setCcvCode(formattedValue);
    };

    const applyDiscount = async () => {
        try {
            const response = await axios.get(`${baseURL}discount/GetDiscountByCouponCode/${couponCode}`);
            const discountData = response.data;

            if (discountData) {
                let discountAmount = 0;

                if (discountData.discountType === 'Percentage') {
                    discountAmount = (price * discountData.discountValue) / 100;
                } else if (discountData.discountType === 'Amount') {
                    discountAmount = discountData.discountValue;
                }

                setFinalPrice(price - discountAmount);
                setDiscountMessage('Discount applied');
                setDiscountId(discountData.id);
            } else {
                setDiscountMessage('Invalid coupon');
                setFinalPrice(price);
            }
        } catch (error) {
            setDiscountMessage('Invalid coupon');
            setFinalPrice(price);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isProcessing) {
            setIsProcessing(true);
            try {
                const groupResponse = await axios.post(
                    `${baseURL}group`,
                    JSON.stringify({
                        name,
                        accountNumber: '',
                        imagePath: '',
                        discription: title,
                        firstName,
                        lastName,
                        emailAddress: email,
                        phoneNumber: phone,
                        street: streetAddress,
                        street2: '',
                        city,
                        state,
                        country,
                        zip: postalCode,
                        isActive: true,
                        createdAt: new Date().toISOString(),
                        createdBy: `${firstName} ${lastName}`,
                        uploadedAt: '',
                        uploadedBy: '',
                        url: '',
                        currency: '',
                        timeZone: '',
                        alias: '',
                        employeeCount: 0,
                        fax: '',
                        website: '',
                        adminUserId: 0
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
                const groupId = groupResponse.data.id;

                const userResponse = await axios.post(
                    `${baseURL}users`,
                    JSON.stringify({
                        groupId,
                        emailAddress: email,
                        userName,
                        password,
                        language: "English",
                        firstName,
                        lastName,
                        dateFormat: "MM/dd/yyyy",
                        mobilPhoneNumber: phone,
                        roleId: 1
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
                const userId = userResponse.data;

                await axios.put(
                    `${baseURL}group`,
                    JSON.stringify({
                        id: groupId,
                        name,
                        accountNumber: `CDIR-${10000 + groupId}`,
                        imagePath: '',
                        discription: title,
                        firstName,
                        lastName,
                        emailAddress: email,
                        phoneNumber: phone,
                        street: streetAddress,
                        street2: '',
                        city,
                        state,
                        country,
                        zip: postalCode,
                        isActive: true,
                        createdAt: new Date().toISOString(),
                        createdBy: `${firstName} ${lastName}`,
                        uploadedAt: '',
                        uploadedBy: '',
                        url: '',
                        currency: '',
                        timeZone: '',
                        alias: '',
                        employeeCount: 0,
                        fax: '',
                        website: '',
                        adminUserId: userId,
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );

                if (source === 'free-trial' || source === 'register') {
                    await postSubscription(groupId, userId, null);
                } else {
                    if (selectedMethod === 'Credit Card') {
                        await postCreditCard(groupId, userId);
                    } else if (selectedMethod === 'Bank ACH') {
                        await postBankAch(groupId, userId);
                    }
                }

            } catch (error) {
                console.error(error);
                setIsProcessing(false);
            }
        }
    };

    const postCreditCard = async (groupId: number, userId: number) => {
        await axios.post(
            `${baseURL}creditCard`,
            JSON.stringify({
                creditCardNumber: card,
                expirationDate,
                cardholderName: cardHolderName,
                ccvCode,
                cardZipCode: 0,
                userId,
                paymentGateway: '',
                brand: '',
                groupId,
                isActive: true
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )
            .then(() => {
                postPaymentMethod(groupId, userId);
            })
            .catch(error => {
                setIsProcessing(false);
                console.error(error)
            })
    };

    const postBankAch = async (groupId: number, userId: number) => {
        await axios.post(
            `${baseURL}achnfo`,
            JSON.stringify({
                accountNumber: bankAccountNumber,
                accountType,
                routingNumber,
                bankName,
                bankPhone: bankPhoneNumber,
                accountHolderName,
                useAccountInfoInFuture: useAccountForFuture,
                signedAcknowledgement: signedAuthorization,
                userId,
                paymentGateway: '',
                brand: '',
                groupId,
                isActive: true,
                createdAt: new Date().toISOString(),
                createdBy: `${firstName} ${lastName}`,
                updatedAt: '',
                updatedBy: ''
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )
            .then(() => {
                postPaymentMethod(groupId, userId);
            })
            .catch(error => {
                setIsProcessing(false);
                console.error(error)
            })
    };

    const postPaymentMethod = async (groupId: number, userId: number) => {
        await axios.post(
            `${baseURL}paymentMethod`,
            JSON.stringify({
                groupId,
                userId,
                methodType: selectedMethod,
                details: 'Online Payment',
                expiryDate: expirationDate,
                createdAt: new Date().toISOString(),
                createdBy: `${firstName} ${lastName}`,
                updatedAt: '',
                updatedBy: '',
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )
            .then((response) => {
                postSubscription(groupId, userId, response.data);
            })
            .catch(error => {
                setIsProcessing(false);
                console.error(error)
            })
    };

    const startDate = new Date();
    let endDate: Date;

    if (billingCycle === 'month') {
        endDate = addMonths(startDate, 1);
    } else if (billingCycle === 'year') {
        endDate = addYears(startDate, 1);
    } else {
        throw new Error('Invalid billing cycle');
    }

    const postSubscription = async (groupId: number, userId: number, paymentMethodId: number | null) => {
        try {
            const subscriptionResponse = await axios.post(
                `${baseURL}subscription`,
                JSON.stringify({
                    groupId,
                    userId,
                    planId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    status: 'active',
                    createdAt: new Date().toISOString(),
                    createdBy: `${firstName} ${lastName}`,
                    updatedAt: '',
                    updatedBy: '',
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            const subscriptionId = subscriptionResponse.data;

            if (paymentMethodId) {
                await axios.post(
                    `${baseURL}payment`,
                    JSON.stringify({
                        subscriptionId,
                        paymentType: 'online',
                        dateofPayment: new Date().toISOString(),
                        amount: finalPrice.toFixed(2),
                        reference: 'string',
                        status: 'pending',
                        createdAt: new Date().toISOString(),
                        createdBy: `${firstName} ${lastName}`,
                        updatedAt: '',
                        updatedBy: '',
                        syncedWithAccounting: false,
                        groupId,
                        paymentMethodId
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                );
            }

            if (discountId) {
                await postSubscriptionDiscount(subscriptionId, groupId, userId, discountId);
            }

            await postBillingHistory(subscriptionId, groupId);

        } catch (error) {
            setIsProcessing(false);
            console.error("Error in postSubscription:", error);
        }
    };

    const postSubscriptionDiscount = async (
        subscriptionId: number,
        groupId: number,
        userId: number,
        discountId: number
    ) => {
        try {
            await axios.post(
                `${baseURL}subscriptionDiscount`,
                JSON.stringify({
                    subscriptionId,
                    groupId,
                    userId,
                    discountId,
                    changeDate: new Date().toISOString(),
                    changeDescription: 'Discount Applied',
                    appliedAt: new Date().toISOString(),
                    endAt: endDate.toISOString(),
                    createdAt: new Date().toISOString(),
                    createdBy: `${firstName} ${lastName}`,
                    updatedAt: '',
                    updatedBy: '',
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
        } catch (error) {
            console.error("Error applying discount to subscription:", error);
        }
    };

    const postBillingHistory = async (
        subscriptionId: number,
        groupId: number,
    ) => {
        try {
            await axios.post(
                `${baseURL}billingHistory`,
                JSON.stringify({
                    subscriptionId,
                    changeDate: new Date().toISOString(),
                    changeDescription: 'Subscription created',
                    groupId,
                    invoiceId: 0,
                    createdAt: new Date().toISOString(),
                    createdBy: `${firstName} ${lastName}`,
                    updatedAt: '',
                    updatedBy: '',
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            resetForm();
        } catch (error) {
            console.error('Error in postBillingHistory:', error);
        }
    };

    const resetForm = () => {
        setName('');
        setFirstName('');
        setLastName('');
        setTitle('');
        setStreetAddress('');
        setCity('');
        setState('');
        setPostalCode('');
        setCountry('');
        setPhone('');
        setCard('');
        setCardHolderName('');
        setExpirationDate('');
        setCcvCode('');
        setBankAccountNumber('');
        setAccountType('');
        setRoutingNumber('');
        setBankName('');
        setBankPhoneNumber('');
        setAccountHolderName('');
        setEmail('');
        setPassword('');
        setUserName('');
        setDiscountId(null);
        setSelectedMethod('');
        setUseAccountForFuture(false);
        setSignedAuthorization(false);
        setFinalPrice(0);
        setIsProcessing(false);
        navigate('/checkout/thank-you');
    };

    const getMessageColor = () => {
        return discountMessage === 'Discount applied' ? 'text-green-500' : 'text-red-500';
    };

    return (
        <>
            <HeroSection mainHeading="Checkout" />
            <div className="w-full flex flex-col sm:flex-row justify-center">
                <div className="w-full px-6 py-8 sm:w-4/6 sm:px-20 sm:py-10">

                    {(source !== 'free-trial' && source !== 'register') && (
                        <div className="mb-4 p-4 bg-slate-50 rounded">
                            <h3 className="text-[#181842] font-semibold text-2xl mb-6">
                                Pricing Info
                            </h3>
                            <p>Membership: {planName}</p>
                            <p>Price: ${price.toFixed(2)}</p>
                            <p>Billing Cycle: {billingCycle}</p>

                            <div className="mt-4 flex flex-col sm:flex-row lg:flex-col lg:items-start">
                                <input
                                    type="text"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    placeholder="Enter coupon code"
                                    className="border p-2 text-sm sm:text-base sm:flex-grow lg:flex-grow-0 lg:w-1/2"
                                />
                                <button
                                    onClick={applyDiscount}
                                    className="mt-2 sm:mt-0 sm:ml-2 lg:ml-0 lg:mt-2 py-2 px-4 text-sm bg-[#181842] text-white rounded"
                                >
                                    Apply Discount
                                </button>
                            </div>

                            {discountMessage && (
                                <div className="mt-4">
                                    <p className={getMessageColor()}>{discountMessage}</p>
                                </div>
                            )}

                            <div className="mt-4">
                                <p className="text-lg font-semibold">Total Price: ${finalPrice.toFixed(2)}</p>
                            </div>
                        </div>
                    )}

                    <h1 className="text-[#181842] font-semibold text-3xl my-6">
                        My Address
                    </h1>

                    <form className="grid grid-cols-1 sm:grid-cols-2 gap-8" onSubmit={handleSubmit}>
                        <div className="mb-1">
                            <Input
                                type="text"
                                label="Company or Organization"
                                pattern=""
                                title=""
                                inputValue={name}
                                setInputValue={setName}
                            />

                            <div className='flex flex-row gap-4'>
                                <Input
                                    type="text"
                                    label="First Name"
                                    pattern=""
                                    title=""
                                    inputValue={firstName}
                                    setInputValue={setFirstName}
                                />

                                <Input
                                    type="text"
                                    label="Last Name"
                                    pattern=""
                                    title=""
                                    inputValue={lastName}
                                    setInputValue={setLastName}
                                />
                            </div>

                            <Input
                                type="text"
                                label="Title"
                                pattern=""
                                title=""
                                inputValue={title}
                                setInputValue={setTitle}
                            />

                            <Input
                                type="text"
                                label="Street Address"
                                pattern=""
                                title=""
                                inputValue={streetAddress}
                                setInputValue={setStreetAddress}
                            />

                            <Input
                                type="text"
                                label="City"
                                pattern=""
                                title=""
                                inputValue={city}
                                setInputValue={setCity}
                            />

                            <div className='flex flex-row gap-4'>
                                <Input
                                    type="text"
                                    label="State / Province"
                                    pattern=""
                                    title=""
                                    inputValue={state}
                                    setInputValue={setState}
                                />

                                <Input
                                    type="text"
                                    label="Postal Code"
                                    pattern="^\d*$"
                                    title=""
                                    inputValue={postalCode}
                                    setInputValue={handlePostalCodeInputChange}
                                />

                            </div>

                            <Input
                                type="text"
                                label="Country"
                                pattern=""
                                title=""
                                inputValue={country}
                                setInputValue={setCountry}
                            />

                            <Input
                                type="text"
                                label="Phone Number"
                                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                                title="Please Enter a 10 digits numbers."
                                inputValue={phone}
                                setInputValue={(value) => onChangePhoneNumber(value, "phone")}
                            />
                        </div>

                        <div className="mb-1">
                            {(source !== 'free-trial' && source !== 'register') && (
                                <div className="mb-1 p-3 bg-slate-50 rounded">
                                    <h3 className="text-[#181842] font-semibold text-2xl mb-6">Payment Info</h3>

                                    <div className="mb-4 flex flex-row sm:items-center sm:space-y-0 space-x-4">
                                        <label className="text-[#181842] flex items-center">
                                            <input
                                                type="radio"
                                                value="Credit Card"
                                                checked={selectedMethod === "Credit Card"}
                                                onChange={handleMethodChange}
                                                className="mr-2"
                                            />
                                            Credit Card
                                        </label>

                                        <label className="text-[#181842] flex items-center">
                                            <input
                                                type="radio"
                                                value="Bank ACH"
                                                checked={selectedMethod === "Bank ACH"}
                                                onChange={handleMethodChange}
                                                className="mr-2"
                                            />
                                            Bank ACH
                                        </label>
                                    </div>

                                    {selectedMethod === 'Credit Card' && (
                                        <>
                                            <Input
                                                type="text"
                                                label="Credit or debit card"
                                                pattern="[0-9]*"
                                                title=""
                                                inputValue={card}
                                                setInputValue={handleCardInputChange}
                                            />

                                            <Input
                                                type="text"
                                                label="Cardholder name"
                                                pattern="[A-Za-z\s]*"
                                                title=""
                                                inputValue={cardHolderName}
                                                setInputValue={handleCardHolderNameInputChange}
                                            />

                                            <div className="flex flex-col sm:flex-row sm:gap-4">
                                                <Input
                                                    type="text"
                                                    label="Expiration Date"
                                                    pattern="^(0[1-9]|1[0-2])\/\d{1,4}$"
                                                    title="Please enter a valid expiration date in the format MM/YY"
                                                    inputValue={expirationDate}
                                                    setInputValue={handleExpirationDateInputChange}
                                                />

                                                <Input
                                                    type="text"
                                                    label="CCV Code"
                                                    pattern="[0-9]*"
                                                    title=""
                                                    inputValue={ccvCode}
                                                    setInputValue={handleCcvCodeInputChange}
                                                />
                                            </div>
                                            {expirationDateError && !isExpirationDateValid && (
                                                <div className="flex items-center mt-1 text-xs text-red-500">
                                                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                                                    <span>{expirationDateError}</span>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {selectedMethod === 'Bank ACH' && (
                                        <>
                                            <div className="flex flex-col sm:flex-row sm:gap-4">
                                                <Input
                                                    type="text"
                                                    label="Bank Name"
                                                    pattern=""
                                                    title=""
                                                    inputValue={bankName}
                                                    setInputValue={setBankName}
                                                />

                                                <Input
                                                    type="text"
                                                    label="Account Number"
                                                    pattern=""
                                                    title=""
                                                    inputValue={bankAccountNumber}
                                                    setInputValue={setBankAccountNumber}
                                                />
                                            </div>

                                            <div className="flex flex-col sm:flex-row sm:gap-4">
                                                <Input
                                                    type="select"
                                                    label="Account Type"
                                                    pattern=""
                                                    title=""
                                                    options={[
                                                        { value: '', label: 'Account Type' },
                                                        { value: 'checking', label: 'Checking' },
                                                        { value: 'saving', label: 'Saving' },
                                                    ]}
                                                    inputValue={accountType}
                                                    setInputValue={setAccountType}
                                                />

                                                <Input
                                                    type="text"
                                                    label="Routing Number"
                                                    pattern=""
                                                    title=""
                                                    inputValue={routingNumber}
                                                    setInputValue={setRoutingNumber}
                                                />
                                            </div>

                                            <div className="flex flex-col sm:flex-row sm:gap-4">
                                                <Input
                                                    type="text"
                                                    label="Account Holder Name"
                                                    pattern=""
                                                    title=""
                                                    inputValue={accountHolderName}
                                                    setInputValue={setAccountHolderName}
                                                />

                                                <Input
                                                    type="text"
                                                    label="Phone Number"
                                                    pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                                                    title="Please Enter a 10 digits number."
                                                    inputValue={bankPhoneNumber}
                                                    setInputValue={(value) => onChangePhoneNumber(value, "bankPhone")}
                                                />
                                            </div>

                                            <div className="flex flex-col gap-2 mt-2">
                                                <label className="flex items-start">
                                                    <input
                                                        type="checkbox"
                                                        checked={useAccountForFuture}
                                                        onChange={(e) => setUseAccountForFuture(e.target.checked)}
                                                        className="mt-1.5"
                                                    />
                                                    <span className="ml-2">Use this account information in the future</span>
                                                </label>

                                                <label className="flex items-start">
                                                    <input
                                                        type="checkbox"
                                                        checked={signedAuthorization}
                                                        onChange={(e) => setSignedAuthorization(e.target.checked)}
                                                        className="mt-1.5"
                                                        required
                                                    />
                                                    <span className="ml-2">
                                                        I authorize the above business to debit my bank account as outlined
                                                        in the payment terms of this agreement.
                                                    </span>
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            <h3 className="text-[#181842] font-semibold text-2xl my-4">
                                Create Account
                            </h3>

                            <Input
                                type="text"
                                label="Username"
                                pattern=""
                                title=""
                                inputValue={userName}
                                setInputValue={setUserName}
                                autoComplete="new-username"
                            />
                            <div className="mb-1">
                                {showUsernameSuccessMessage && isUsernameAvailable === true && (
                                    <div className="flex items-center -mt-2 mb-3 text-xs text-green-500">
                                        <FaCheckCircle className="mr-1" style={{ fontSize: 16 }} />
                                        <span>Username is available</span>
                                    </div>
                                )}
                                {showUsernameErrorMessage && isUsernameAvailable === false && (
                                    <div className="flex items-center -mt-2 mb-3 text-xs text-red-500">
                                        <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                                        <span>Username is already exist</span>
                                    </div>
                                )}
                            </div>

                            <Input
                                type="email"
                                label="Email"
                                pattern=""
                                title=""
                                inputValue={email}
                                setInputValue={setEmail}
                                autoComplete="new-email"
                            />
                            {!isEmailValid && (
                                <div className="flex items-center -mt-2 mb-3 text-xs text-red-500">
                                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                                    <span>Please enter a valid email address</span>
                                </div>
                            )}
                            {showEmailSuccessMessage && !isEmailAvailable && (
                                <div className="flex items-center -mt-2 mb-3 text-xs text-green-500">
                                    <FaCheckCircle className="mr-1" style={{ fontSize: 16 }} />
                                    <span>Email is available</span>
                                </div>
                            )}
                            {showEmailErrorMessage && isEmailAvailable && (
                                <div className="flex items-center -mt-2 mb-3 text-xs text-red-500">
                                    <MdErrorOutline className="mr-1" style={{ fontSize: 16 }} />
                                    <span>Email is already exist</span>
                                </div>
                            )}

                            <div className="relative">
                                <Input
                                    type={passwordTextType ? 'text' : 'password'}
                                    label="Password"
                                    pattern=""
                                    title=""
                                    inputValue={password}
                                    setInputValue={handlePasswordChange}
                                    autoComplete="new-password"
                                />
                                <div
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordTextType ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-5 h-5 absolute right-4 cursor-pointer" viewBox="0 0 32 32" onClick={togglePasswordVisibility}>
                                            <path d="M8.10869891,20.8913011 C4.61720816,18.8301147 3,16 3,16 C3,16 7,9 16,9 C17.3045107,9 18.5039752,9.14706466 19.6014388,9.39856122 L18.7519017,10.2480983 C17.8971484,10.0900546 16.9800929,10 16,10 C8,10 4.19995117,16 4.19995117,16 C4.19995117,16 5.71472808,18.3917225 8.84492713,20.1550729 L8.10869891,20.8913011 L8.10869891,20.8913011 L8.10869891,20.8913011 Z M12.398561,22.601439 C13.4960246,22.8529356 14.6954892,23.0000001 16,23 C25,22.999999 29,16 29,16 C29,16 27.3827918,13.1698856 23.8913008,11.1086992 L23.1550727,11.8449273 C26.2852719,13.6082776 27.8000488,16 27.8000488,16 C27.8000488,16 24,21.999999 16,22 C15.019907,22.0000001 14.1028515,21.9099455 13.2480981,21.7519019 L12.398561,22.601439 L12.398561,22.601439 L12.398561,22.601439 Z M19.8986531,15.1013469 C19.9649658,15.3902115 20,15.6910144 20,16 C20,18.2091391 18.2091391,20 16,20 C15.6910144,20 15.3902115,19.9649658 15.1013469,19.8986531 L16,19 C16.7677669,19.0000001 17.5355339,18.7071068 18.1213203,18.1213203 C18.7071068,17.5355339 19.0000001,16.7677669 19,16 L19.8986531,15.1013469 L19.8986531,15.1013469 L19.8986531,15.1013469 Z M16.8986531,12.1013469 C16.6097885,12.0350342 16.3089856,12 16,12 C13.7908609,12 12,13.7908609 12,16 C12,16.3089856 12.0350342,16.6097885 12.1013469,16.8986531 L13,16 C12.9999999,15.2322331 13.2928932,14.4644661 13.8786797,13.8786797 C14.4644661,13.2928932 15.2322331,12.9999999 16,13 L16.8986531,12.1013469 L16.8986531,12.1013469 L16.8986531,12.1013469 Z M24,7 L7,24 L8,25 L25,8 L24,7 L24,7 Z" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" className="w-5 h-5 absolute right-4 cursor-pointer" viewBox="0 0 128 128" onClick={togglePasswordVisibility}>
                                            <path d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z" />
                                        </svg>
                                    )}
                                </div>
                            </div>
                            {!isPasswordValid && password.length > 0 && (
                                <p className="text-red-500 text-xs mt-1">
                                    Password must be at least 8 characters long and include at least one lowercase letter (a-z),
                                    one uppercase letter (A-Z),
                                    one number (0-9),
                                    and one special character (!@#$%^&*()).
                                </p>
                            )}

                            <div className='my-10 text-center'>
                                <button
                                    type="submit"
                                    className={`py-3 px-16 rounded-sm font-bold transition-colors duration-300 ${(isPasswordValid && (source === "free-trial" || source === "register")) ||
                                        (isPasswordValid && selectedMethod === "Credit Card" ? isExpirationDateValid : true)
                                        ? 'bg-[#181842] text-white hover:bg-blue-800'
                                        : 'bg-gray-400 text-white cursor-not-allowed'
                                        } ${isProcessing ? 'bg-[#181842]' : ''}`}
                                    disabled={
                                        !isPasswordValid ||
                                        (source !== "free-trial" && source !== "register" && selectedMethod === "Credit Card" ? !isExpirationDateValid : false) ||
                                        isProcessing
                                    }
                                >
                                    {isProcessing ? "Processing..." : "Place Order Now"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <YourFreeTrialOffer />
            </div>
        </>
    );
};

export default Checkout;