import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { onChangePhoneNumber } from '../../utils/phoneNumberRegex';
import { getEvent } from '../../services/Event';

const ViewMemberEvent = () => {
    const navigate = useNavigate();
    const startDateRef = useRef<HTMLInputElement>(null);
    const endDateRef = useRef<HTMLInputElement>(null);
    const { id } = useParams();
    const [eventData, setEventData] = useState<any>({
        id: 0,
        eventName: "",
        groupId: 0,
        subGroupId: 0,
        startDate: "",
        endDate: "",
        sessions: "",
        eventCoordinatorNameEmailAddress: "",
        eventCoordinatorEmail: "",
        eventCoordinatorPhone: "",
        guest: "",
        description: "",
        isActive: true,
        location: "",
    });


    useEffect(() => {
        if (id) {
            getEvent(id)
                .then(res => {
                    setEventData(res);
                })
                .catch(error => console.log(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const closeModal = () => navigate(-1);

    return (
        <div className="w-full">
            <div className="my-2">
                <div className="py-8 px-6 relative bg-white">
                    <h2 className="text-xl text-blue-500 font-bold">Event</h2>
                    <form className="mt-8 grid sm:grid-cols-2 gap-6 text-blue-500">
                        <div>
                            <label className="font-semibold text-sm">Event Name</label>
                            <input type='text' placeholder='Enter Event Name'
                                readOnly
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={eventData.eventName}
                                onChange={(e) => setEventData({ ...eventData, eventName: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Event Coordinator Name</label>
                            <input type='text' placeholder='Enter Event Coordinator Name'
                                readOnly
                                className="w-full rounded py-2.5 px-4 mt-2 border-2 text-sm text-black outline-[#007bff]"
                                value={eventData.eventCoordinatorNameEmailAddress}
                                onChange={(e) => setEventData({ ...eventData, eventCoordinatorNameEmailAddress: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Event Coordinator Email</label>
                            <input type='email' placeholder='Enter Event Coordinator Email'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.eventCoordinatorEmail}
                                onChange={(e) => setEventData({ ...eventData, eventCoordinatorEmail: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Event Coordinator Phone Number</label>
                            <input type='text' placeholder='Enter Event Coordinator Phone No.'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                pattern="[\(][0-9]{3}[\)][\s]{1}[0-9]{3}[\-]{1}[0-9]{4}"
                                value={eventData.eventCoordinatorPhone}
                                onChange={(e) => setEventData({ ...eventData, eventCoordinatorPhone: onChangePhoneNumber(e.target.value) })}
                            />
                        </div>

                        <div>
                            <label className="font-semibold text-sm">Start Date</label>
                            <input ref={startDateRef} type='datetime-local'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.startDate}
                                onChange={(e) => setEventData({ ...eventData, startDate: e.target.value })}

                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">End Date</label>
                            <input ref={endDateRef} type='datetime-local'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.endDate}
                                onChange={(e) => setEventData({ ...eventData, endDate: e.target.value })}

                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Guest</label>
                            <input type='text'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.guest}
                                onChange={(e) => setEventData({ ...eventData, guest: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Sessions</label>
                            <input type='text'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.sessions}
                                onChange={(e) => setEventData({ ...eventData, sessions: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Location</label>
                            <input type='text'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.location}
                                onChange={(e) => setEventData({ ...eventData, location: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Active</label>
                            <div className="flex items-center font-semibold text-sm gap-4  h-1/2">
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        value="Yes"
                                        name="active"
                                        readOnly
                                        style={{ fontSize: "12px" }}
                                        checked={eventData.isActive ? true : false}

                                    />
                                    <label>Yes</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="radio"
                                        value="No"
                                        name="active"
                                        readOnly
                                        style={{ fontSize: "12px" }}
                                        checked={eventData.isActive ? false : true}

                                    />
                                    <label>No</label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className="font-semibold text-sm">Description</label>
                            <textarea placeholder='Description'
                                readOnly
                                className="w-full rounded py-2.5 px-4 border-2 mt-2 text-sm text-black outline-[#007bff]"
                                value={eventData.description}
                                onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
                            />
                        </div>
                        <div />

                        <div className="flex max-sm:flex-col gap-4 !mt-4">
                            <button
                                type="button"
                                className="px-6 py-2 min-w-[150px] rounded text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ViewMemberEvent