import { useEffect, useState } from 'react'
import Topbar from './Topbar'
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hook/useAuth';
import Member from '../pages/member/Member';

const MemberMainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = Cookies.get("role-my-cdir");
  const { setAuth }: any = useAuth();
  const [navbarHeader, setNavbarHeader] = useState<any[]>([
    {
      name: "Member",
      link: "members",
      isChildVisible: false,
      child: []
    },
    {
      name: "Event",
      link: "/member/event",
      isChildVisible: false,
      child: []
    },
    {
      name: "Help",
      link: "/help",
      isChildVisible: false,
      child: []
    },
    {
      name: "Tutorial",
      link: "/tutorial",
      isChildVisible: false,
      child: []
    },
    {
      name: "Blog",
      link: "/blog",
      isChildVisible: false,
      child: []
    },
    {
      name: "FAQ",
      link: "member-f-a-q",
      isChildVisible: false,
      child: []
    },
    {
      name: "My Account",
      link: "my-account",
      isChildVisible: false,
      child: []
    }
  ]);

  useEffect(() => {
    const headerForMember = "member";
    if (location.pathname.replaceAll("/", "").endsWith(headerForMember)) {
      if (role?.toString()?.toLowerCase() === "member") {
        navigate("/member/members", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const logOut = () => {
    setAuth(null);
    Cookies.remove("role-my-cdir");
    Cookies.remove("groupId-my-cdir");
    Cookies.remove("memberId-my-cdir");
    Cookies.remove("groupName-my-cdir");
    Cookies.remove("groupImage-my-cdir");
    Cookies.remove("token-my-cdir");
    navigate("/sign-in", { state: { from: location }, replace: true });
  }

  return (
    <section className="admin___layout__container">
      <Topbar
        navbarHeader={navbarHeader}
        setNavbarHeader={setNavbarHeader}
        logOut={logOut}
      />

      <div className="divider-view w-full mt-[72px]" />

      <section className="admin__body__container flex flex-row min-h-[calc(100vh-72px)]">
        <Member />
      </section>

    </section>
  )
}

export default MemberMainLayout;